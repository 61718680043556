@import 'abstracts/spacers';
@import 'abstracts/helpers';

.contact-card {
  text-align: left;
  @avatar-dimension: 45px; // Magic number for product specialist image sizing

  p + p {
    margin-top: 5px; // Override original rule to match design
  }

  .product-specialist-name {
    color: @B600;
    font-weight: 600;
  }

  .avatar-dimension(md, @avatar-dimension);

  ul.cta-wrapper {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: (@spacer / 2);
    padding: 0;
    margin: 0;

    .product-specialist-name {
      color: @B600;
    }

    .chat-button,
    .contact-us-button {
      display: block;
      background: none; // Clear background from default button element
      font-family: @font-family-base;
      color: @anchor-color;
      cursor: pointer;
      padding: 0;

      &:hover,
      &:focus {
        color: @anchor-color-hover; // Override black hover color
        text-decoration: underline;
      }
    }

    .product-specialist-cta-button {
      &:global(.rs-btn-ghost) {
        &.on-products-catalog {
          padding: @spacer * 0.75 @spacer * 3;
        }

        &.on-spp {
          padding: @spacer * 0.75 @spacer * 2;
        }
      }
    }
  }
}
