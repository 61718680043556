@import 'abstracts/variables';
@import 'ProductCard.module';
@import 'abstracts/helpers';

@dimension: 250px;
@icon-color: @B600;

.see-all-card {
  .product-card;

  width: 530px;
  height: 100%;
  min-height: 410px; // Adding enough height for empty carousel
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 @spacer * 2;

  .logo {
    background: white;
    box-shadow: @avatar-box-shadow;
    border-radius: 50%;

    img {
      padding: @spacer !important;
    }
  }

  p {
    color: @B800;
  }

  .browse-button {
    padding: @spacer @spacer * 3;
    margin-top: @spacer * 3;
  }

  @media @below-sm {
    width: 210px;

    .browse-button {
      margin-top: @spacer * 2;
      width: 170px;

      .ellipsis;
    }
  }
}
