@import 'abstracts/variables';
@import 'ProductCard.module';
@import 'abstracts/helpers';

.products-wrapper {
  :global {
    .scroll-container-ref {
      @card-dimension: 250px;
      @card-dimension-small: 210px;

      > div {
        min-width: @card-dimension;
        max-width: @card-dimension;

        &:first-child {
          margin-left: @spacer;
        }

        @media @below-md {
          min-width: @card-dimension-small;
          max-width: @card-dimension-small;
        }
      }
    }
  }
}

.product-carousel {
  max-width: 100%;

  .skeleton-wrapper {
    max-width: 100%;
    flex-flow: row;
  }

  .skeleton-product-card {
    max-width: 250px !important; // Override the inline styles applied on the Placeholder.Graph rsuite component
    height: 428px !important; // Magic number to fix the card height to match the card design
    margin-right: @spacer * 2;
    margin-top: @spacer * 2;
    border-radius: @border-radius;

    &:last-child {
      margin-right: @spacer;

      @media @below-md {
        margin-right: 0;
      }
    }
  }

  h2 {
    overflow-wrap: break-word;
    hyphens: auto;
  }
}

.browse-products-mobile {
  :global {
    .rs-btn-ghost {
      padding: @spacer;
    }
  }
}

.icon-primary {
  color: @primary-darker;
}

.zIndex-1 {
  position: relative;
  z-index: 1;
}
